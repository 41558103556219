import { differenceInDays } from "date-fns";

import { generateEntityId } from "@modules/helpers";
import { getNodeText } from "@components/ScribeEditor/helpers";

export function parseTabletData(tabletData = {}) {
    // This takes into account results from our DB and search results from Algolia
    const parsedTabletData = {
        ...tabletData,
        title: tabletData?.draft?.title || tabletData?.latest?.title || tabletData?.title,
        createdAt: new Date(tabletData.latest?.createdAt || tabletData.createdAt),
        updatedAt: new Date(tabletData.latest?.updatedAt || tabletData.updatedAt),
    };

    return parsedTabletData;
}

export function parseTabletsData(tabletsData = []) {
    return tabletsData.map((tabletData) => parseTabletData(tabletData));
}

export function groupTabletsByCollection(tabletsData = []) {
    return tabletsData.reduce((tabletsByCollection, tablet) => {
        const parsedTabletData = parseTabletData(tablet);

        parsedTabletData.collections.forEach((collection) => {
            if (tabletsByCollection.has(collection.id)) {
                const currentSelection = tabletsByCollection.get(collection.id);
                tabletsByCollection.set(collection.id, {
                    ...currentSelection,
                    usersTablets: [...currentSelection.usersTablets, parsedTabletData],
                });
            } else {
                tabletsByCollection.set(collection.id, {
                    id: collection.id,
                    name: collection.name,
                    allTablets: collection.tablets,
                    usersTablets: [parsedTabletData],
                });
            }
        });

        return tabletsByCollection;
    }, new Map());
}

export function getDefaultTabletSectionValue(tabletCount = 0) {
    return [generateMainSectionHeading(tabletCount), generateSectionParagraph()];
}

export function getDefaultTabletValue() {
    return [generateMainHeading(), generateParagraph()];
}

/**
 * Normalise the content of a tablet in the context of a document. Every tablet needs to have
 * a main heading and a first paragraph, so any tablets received from the DB without these gets
 * them injected in. You may wonder why we don't just enforce that every new tablet has to have
 * these on creation. Unfortunately this doesn't protect against my mistakes when coding up the app
 * and when we open up our API that will give the user the oppurtunity to mess with the content in wierd ways.
 * For now this acts as a great stop gap, combined with preventing tablet publication if the tablet doesnt
 * have a title.
 * @param {*} content The Slate content of the tablet
 * @param {*} tabletCount The position of this tablet
 */
export function normaliseTabletSectionContent(content = [], tabletCount) {
    const mainHeading = content.find(({ type }) => type === "main-heading");
    const firstContentBlock = content.find(({ type }) => type !== "main-heading");

    let normalisedContent = [...content];
    if (!mainHeading) {
        normalisedContent = [generateMainSectionHeading(tabletCount), ...normalisedContent];
    }
    if (!firstContentBlock) {
        normalisedContent = [...normalisedContent, generateSectionParagraph()];
    }

    return normalisedContent;
}

export function normaliseTabletContent(content = [], shared, metadata) {
    const mainHeading = content.find(({ type }) => type === "main-heading");
    const firstContentBlock = content.find(({ type }) => type !== "main-heading");

    let normalisedContent = [...content];
    if (!mainHeading) {
        normalisedContent = [generateMainHeading(), ...normalisedContent];
    }
    if (!firstContentBlock) {
        normalisedContent = [...normalisedContent, generateParagraph()];
    }

    if (!shared) {
        normalisedContent = removeAuthorNode(normalisedContent, metadata);
    } else {
        normalisedContent = upsertAuthorNode(normalisedContent, metadata);
    }

    return normalisedContent;
}

/**
 * Pages that don't have a heading are called Notes. They allow you to write throwaway ideas without
 * having to worry about cluttering up your workspace. When showing a preview of a notes content we
 * want to strip away anything that isn't content. This includes placeholder text in the header, empty
 * paragraphs, ect.
 * @param {object[]} content The blocks that make up the note
 */
export function normaliseNotePreviewContent(content = []) {
    return content.reduce((normalisedContent, block) => {
        const text = getNodeText(block);

        if (!text) return normalisedContent;
        else return [...normalisedContent, block];
    }, []);
}

export function tabletHasMainHeading(content = []) {
    const mainHeading = content.find(({ type }) => type === "main-heading");

    return !!mainHeading;
}

export function tabletHasMetadataSection(content = []) {
    const metadataNode = content.find(({ type }) => type === "editor-metadata");
    return !!metadataNode;
}

export function getMainHeadingText(content = []) {
    const mainHeading = content.find(({ type }) => type === "main-heading");

    if (!mainHeading) return null;
    const text = mainHeading.children[0]?.text;

    return text;
}

function generateMainSectionHeading(tabletCount) {
    return {
        id: generateEntityId(),
        type: "main-heading",
        children: [{ text: "", placeholder: `Section #${tabletCount + 1}` }],
    };
}

function generateSectionParagraph() {
    return {
        id: generateEntityId(),
        type: "paragraph",
        children: [
            {
                text: "",
                placeholder:
                    "Think of a section as a stand alone piece of information. A document can have as many sections as you would like.",
            },
        ],
    };
}

function generateMainHeading() {
    return {
        id: generateEntityId(),
        type: "main-heading",
        children: [{ text: "" }],
        placeholder: "Untitled",
    };
}

function generateParagraph() {
    return {
        id: generateEntityId(),
        type: "paragraph",
        children: [
            {
                text: "",
            },
        ],
        placeholder: "Type  /  for commands. Highlight text for more options.",
    };
}

export function getTabletStatus(status, createdAt, updatedAt) {
    const daysSinceCreated = differenceInDays(new Date(), createdAt);
    const daysSinceUpdated = differenceInDays(new Date(), updatedAt);

    const hasUpdated = daysSinceCreated !== daysSinceUpdated;
    const isOutdated = status === "outdated";

    let badgeState = {};
    if (isOutdated) badgeState = { status: "outdated", type: "danger" };
    else if (!hasUpdated && daysSinceCreated < 4) badgeState = { status: "new", type: "attention" };
    else if (daysSinceUpdated < 7) badgeState = { status: "updated", type: "attention" };

    return badgeState;
}

export function upsertAuthorNode(editorValue, metadataProps = {}) {
    if (!tabletHasMainHeading(editorValue)) return editorValue;

    let editorValueToMutate = [...editorValue];

    if (tabletHasMetadataSection(editorValue)) {
        const metadataNode = editorValue.find(({ type }) => type === "editor-metadata");
        const metadataNodeIndex = editorValue.findIndex(({ type }) => type === "editor-metadata");

        const newNode = {
            ...metadataNode,
            ...metadataProps,
        };

        editorValueToMutate.splice(metadataNodeIndex, 1);
        editorValueToMutate.splice(1, 0, newNode);
        return editorValueToMutate;
    } else {
        const metadataNode = {
            type: "editor-metadata",
            children: [{ text: "" }],
            ...metadataProps,
        };

        editorValueToMutate.splice(1, 0, metadataNode);
        return editorValueToMutate;
    }
}

export function removeAuthorNode(editorValue) {
    if (tabletHasMetadataSection(editorValue)) {
        let editorValueToMutate = [...editorValue];

        const metadataNodeIndex = editorValue.findIndex(({ type }) => type === "editor-metadata");
        editorValueToMutate.splice(metadataNodeIndex, 1);
        return editorValueToMutate;
    } else {
        return editorValue;
    }
}

export function removeUnpersistedNodes(editorValue = []) {
    return [...editorValue].filter(({ type }) => type !== "editor-metadata");
}

export function getEditorValueFromBlocks(blocks = []) {
    return [...blocks].sort((b1, b2) => b1.order - b2.order).map(({ content }) => JSON.parse(content));
}
