import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useRouter } from "next/router";

import { historyContext } from "@context/history";

/**
 * Creates a function that you can invoke to construct a workspace specifc URL
 * for navigating to a specific resource
 */
export function useLazyWorkspaceUrl() {
    const router = useRouter();
    const { workspace: workspaceID } = router.query;

    // WorkspaceIdOverride is needed so you can use it even in environments outside the workspace (e.g. the login page)
    const createWorkspaceUrl = useCallback(
        (href, as, { workspaceId: workspaceIdOverride } = {}) => {
            const workspaceAwareHref = `/[workspace]/${href}`;
            const workspaceAwareAs = `/${workspaceIdOverride || workspaceID}/${as}`;

            return { href: workspaceAwareHref, as: workspaceAwareAs };
        },
        [workspaceID]
    );

    return createWorkspaceUrl;
}

export function useWorkspaceUrl(href, as) {
    const router = useRouter();
    const { workspace: workspaceID } = router.query;
    const workspaceAwareHref = `/[workspace]/${href}`;
    const workspaceAwareAs = `/${workspaceID}/${as}`;

    return { href: workspaceAwareHref, as: workspaceAwareAs };
}

/**
 * Returns the current workspace ID
 */
export function useWorkspace() {
    const router = useRouter();
    const { workspace: workspaceID } = router.query;

    return workspaceID;
}

/**
 * This tells us if we have navigated across route branches. If we navigate up or down the same branch
 * it will return an empty string. For example, /123/home/111 to /123/tablet will trigger this, however
 * /123/tablet/edit to /123/tablet or vice versa will not (because they are child and parent routes of
 * one another). Keep in mind that historyContext works across complete refreshes as well.
 */
export function useHasTraversedBranches() {
    const history = useContext(historyContext);
    const prevUrl = history[history.length - 2];
    const currentUrl = history[history.length - 1];

    const hasTraversedBranches = useMemo(() => {
        if (!currentUrl) return false;
        if (!prevUrl) return true;

        let diff = "";
        for (let i = 0; i < currentUrl.length; i++) {
            if (!prevUrl.charAt(i)) break;
            if (currentUrl.charAt(i) !== prevUrl.charAt(i)) {
                diff = prevUrl.slice(i);
                break;
            }
            // if (i === currentUrl.length - 1 && !!prevUrl.charAt(i + 1)) {
            //     diff = prevUrl.slice(i + 1);
            // }
        }

        return diff !== "";
    }, [prevUrl, currentUrl]);

    return hasTraversedBranches;
}

export function useHasChangedRoutes(subRoutes = false) {
    const history = useContext(historyContext);
    const prevUrl = history[history.length - 2];
    const currentUrl = history[history.length - 1];

    const hasChangedRoutes = useMemo(() => {
        if (!currentUrl) return [false, currentUrl];
        if (!prevUrl) return [true, currentUrl];

        let diff = "";
        for (let i = 0; i < currentUrl.length; i++) {
            // If the current URL has extended past the length of the previous URL then we know we are in a subroute
            if (subRoutes && !prevUrl.charAt(i)) {
                diff = currentUrl.slice(i);
                break;
            }
            if (currentUrl.charAt(i) !== prevUrl.charAt(i)) {
                diff = prevUrl.slice(i);
                break;
            }
            // The previous URL is longer than the current URL
            if (subRoutes && i === currentUrl.length - 1 && !!prevUrl.charAt(i + 1)) {
                diff = prevUrl.slice(i + 1);
            }
        }

        const hasChanged = diff !== "";

        return [hasChanged, currentUrl];
    }, [prevUrl, currentUrl]);

    return hasChangedRoutes;
}
