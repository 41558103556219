import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Router from "next/router";
import formatISO from "date-fns/formatISO";
import produce from "immer";

import { generateEntityId } from "@modules/helpers";
import { removeUnpersistedNodes, getMainHeadingText } from "@modules/tablet-helpers";
import { useHasChangedRoutes } from "@hooks/url-hooks";
import { useWorkspace, useLazyWorkspaceUrl } from "@hooks/url-hooks";
import { usePage } from "@hooks/page-hooks";
import { navigationContext } from "@context/navigation";

const UPSERT_BLOCK = gql`
    mutation upsertBlock($input: UpsertBlockInput!) {
        upsertBlock(input: $input) {
            id
        }
    }
`;

const DELETE_BLOCK = gql`
    mutation deleteBlock($input: DeleteBlockInput!) {
        deleteBlock(input: $input) {
            id
        }
    }
`;

const ORDER_BLOCKS = gql`
    mutation orderAllBlocks($input: OrderAllBlocksInput!) {
        orderAllBlocks(input: $input) {
            id
        }
    }
`;

const UPSERT_TODAYS_DATE_COLLECTION = gql`
    mutation upsertDateCollection($input: UpsertDateCollectionInput!) {
        upsertDateCollection(input: $input) {
            id
            name
        }
    }
`;

const CREATE_PAGE = gql`
    mutation createPage($input: CreateTabletInput!) {
        createTablet(input: $input) {
            id
            createdAt
            updatedAt
            preview

            currentStatus {
                cause
                status
                changedAt
            }

            draft {
                title
                blocks {
                    id
                    content
                    order
                }
            }

            latest {
                id
                published
                revision
                title
                blocks {
                    id
                    content
                    order
                }
            }

            owner {
                id
                firstName
                lastName
            }
        }
    }
`;

const RECORD_PAGE_VIEW = gql`
    mutation recordTabletView($input: RecordTabletViewInput!) {
        recordTabletView(input: $input) {
            viewedAt
            tablet {
                id
                latest {
                    id
                    title
                }

                collections {
                    id
                    name
                }
                connectedTablets {
                    id

                    latest {
                        id
                        title
                    }
                }
            }
        }
    }
`;

const GET_WORKSPACE = gql`
    query workspace($id: ID!) {
        workspace(id: $id) {
            id

            collections {
                id
                name
                type

                tablets {
                    id
                    latest {
                        id
                        title
                    }
                }

                connectedCollections {
                    id
                    name
                }
            }

            tablets {
                id
                latest {
                    id
                    title
                }

                connectedTablets {
                    id
                    latest {
                        id
                        title
                    }
                }

                collections {
                    id
                    name
                }
            }
        }
    }
`;

const PAGE_VIEWS = gql`
    query tabletViews($workspaceId: ID!) {
        tabletViews(filter: { workspace: $workspaceId }, limit: 20) {
            viewedAt
            tablet {
                id
                latest {
                    id
                    title
                }

                collections {
                    id
                    name
                }
                connectedTablets {
                    id

                    latest {
                        id
                        title
                    }
                }
            }
        }
    }
`;

const PAGE_BOOKMARKS = gql`
    query tabletBookmarks($workspaceId: ID!) {
        tabletBookmarks(filter: { workspace: $workspaceId }) {
            createdAt
            tablet {
                id
                deletedAt

                latest {
                    id
                    title
                }

                collections {
                    id
                    name
                }
                connectedTablets {
                    id

                    latest {
                        id
                        title
                    }
                }
            }
        }
    }
`;

const COLLECTION_BOOKMARKS = gql`
    query collectionBookmarks($workspaceId: ID!) {
        collectionBookmarks(filter: { workspace: $workspaceId }) {
            createdAt
            collection {
                id
                name
                shared

                tablets {
                    id
                    latest {
                        id
                        title
                    }
                }

                connectedCollections {
                    id
                    name
                    type
                }
            }
        }
    }
`;

const UPDATE_PAGE_CONTENT = gql`
    mutation updateTabletContent($input: UpdateTabletPageContent!) {
        updateTabletContent(input: $input) {
            id
            title
            preview
            revision
            blocks {
                id
                order
                content
                revision
            }
        }
    }
`;

export function useSimplePageSave(pageId, revision, initialStateHasLoaded, onSave) {
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const [flushing, setFlushing] = useState(false);
    const lastPersistedEditorValueRef = useRef();
    const currentEditorValueRef = useRef();
    const hasPendingChangesRef = useRef();
    const pageRef = useRef();
    const [updatePageContent, { error }] = useMutation(UPDATE_PAGE_CONTENT);

    async function syncToApi() {
        const currentEditorValue = currentEditorValueRef.current;
        const lastPersistedEditorValue = lastPersistedEditorValueRef.current;
        const currentPage = pageRef.current;

        const areEqual = areStatesEqual(currentEditorValue, lastPersistedEditorValue);

        if (areEqual || !currentEditorValue) {
            setHasPendingChanges(false);
            hasPendingChangesRef.current = false;
            return;
        }

        setFlushing(true);

        try {
            await updatePageContent({
                variables: {
                    input: { id: pageId, editorState: JSON.stringify(currentEditorValue), revision },
                },
            });

            setFlushing(false);
            if (onSave) onSave();

            if (pageRef.current === currentPage) {
                lastPersistedEditorValueRef.current = currentEditorValue;

                // If the editor value hasn't changed since we synced to the API then there are no more pending changes
                if (currentEditorValueRef.current === currentEditorValue) {
                    setHasPendingChanges(false);
                    hasPendingChangesRef.current = false;
                }
            }
        } catch (e) {
            setFlushing(false);

            if (e.message !== "tablet/old-revision") {
                window.alert(
                    "There were some issues with saving the content you created in the last 10 seconds. Please refresh the page and continue editing. If this happens again please email us directly at support@scribe.wiki"
                );
            }
        }
    }

    function queueStateChanges(entireNewState, entireCurrentState) {
        const newState = removeUnpersistedNodes(entireNewState);
        const currentState = removeUnpersistedNodes(entireCurrentState);

        // This could be done better but I'm pretty sure this works
        if (!lastPersistedEditorValueRef.current && initialStateHasLoaded) {
            lastPersistedEditorValueRef.current = currentState;
            currentEditorValueRef.current = newState;
        }

        const hasChanged = !areStatesEqual(newState, currentState);
        if (!hasChanged) return;

        currentEditorValueRef.current = newState;

        setHasPendingChanges(true);
        hasPendingChangesRef.current = true;
    }

    function areStatesEqual(newState = [], currentState = []) {
        // If one of these doesn't exist I don't want to b
        if (!newState && !currentState) return true;
        if (!newState || !currentState) return false;

        const newStateDiffs = newState.filter((node) => !currentState.includes(node));
        const currentStateDiffs = currentState.filter((node) => !newState.includes(node));
        return newStateDiffs.length === 0 && currentStateDiffs.length === 0;
    }

    useEffect(() => {
        currentEditorValueRef.current = null;
        lastPersistedEditorValueRef.current = null;
        hasPendingChangesRef.current = false;
        pageRef.current = pageId;
        setHasPendingChanges(false);
    }, [pageId]);

    useEffect(() => {
        if (!initialStateHasLoaded) return;

        const autoSave = setInterval(syncToApi, 10000);

        return () => clearInterval(autoSave);
    }, [pageId, initialStateHasLoaded, revision]);

    // If the browser is closed or refreshed let's send off pending changes
    useEffect(() => {
        function handleBeforeUnload(event) {
            if (!pageId) return;

            // Until we have a better system its best to stop them from leaving until we send the final updates
            if (hasPendingChangesRef.current) {
                event.preventDefault();
                event.returnValue = "";
            }

            syncToApi();
        }

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [pageId, initialStateHasLoaded, revision]);

    async function manualSave() {
        if (!pageId || !initialStateHasLoaded || !hasPendingChangesRef.current) return;
        await syncToApi();
    }

    function resetState(newState) {
        currentEditorValueRef.current = null;
        lastPersistedEditorValueRef.current = newState;
        hasPendingChangesRef.current = false;
        setHasPendingChanges(false);
    }

    useEffect(() => {
        // This will always be called before the pageId changes
        function handleRouteChange() {
            manualSave();
        }

        Router.events.on("routeChangeStart", handleRouteChange);

        return () => Router.events.off("routeChangeStart", handleRouteChange);
    }, [pageId, initialStateHasLoaded, revision]);

    return { queueStateChanges, manualSave, hasPendingChanges, flushing, error, resetState };
}

export function useTabletSave(tabletId) {
    // const prevEditorState = usePrevious(editorState, []);
    const [upsertBlock] = useMutation(UPSERT_BLOCK);
    const [deleteBlock] = useMutation(DELETE_BLOCK);
    const [orderAllBlocks] = useMutation(ORDER_BLOCKS);
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const [flushing, setFlushing] = useState(false);

    const transactionProxy = new Proxy(
        { value: true },
        {
            get(target, key) {
                if (key === "registerFinishListener") {
                    return (callback) => {
                        target._listener = callback;
                    };
                }
                return target[key];
            },
            set(target, key, value) {
                if (key === "value" && value === false && target._listener) {
                    target._listener();
                    target._listener = null;
                }

                target[key] = value;
                return true;
            },
        }
    );

    const transactionInProgressRef = useRef(transactionProxy);
    const autoSaveStateChanges = useRef({ added: [], removed: [], modified: [], moved: [] });
    const editorValueRef = useRef();

    async function syncToApi(changes) {
        const { added, removed, modified, moved } = changes;
        const currentEditorValue = editorValueRef.current;

        /**
         * We want to reset any state changes immediately instead of after a response is returned because users can
         * continue making edits while the request is in flight. If they did and we reset after we get a response we
         * would clear their most recent changes without saving them which is bad. This still has one huge issue which
         * is it doesn't address what happens if some of those in flight requests fail. Right now that isn't an issue
         * because we tell the user to reload, however it will need to be quickly addressed. One option is to put a series
         * identifier on every addition and modification. If either of these types of requests fail we can if they have been
         * replaced in the series, if they have do nothing. If they haven't then fold them back into the new changes to sync.
         */
        resetStateChanges();

        if (!hasChangesToSync(changes) || !currentEditorValue) {
            setHasPendingChanges(false);
            return;
        } else {
            setFlushing(true);
        }

        const additionsInProgress = added.map((node) => {
            return upsertBlock({
                variables: {
                    input: {
                        uuid: node.id,
                        content: JSON.stringify(node),
                        order: getBlockPlacement(currentEditorValue, node.id),
                        tabletId,
                    },
                },
            });
        });

        const modificationsInProgress = modified.map((node) => {
            return upsertBlock({
                variables: {
                    input: {
                        uuid: node.id,
                        content: JSON.stringify(node),
                        order: getBlockPlacement(currentEditorValue, node.id),
                        tabletId,
                    },
                },
                update(cache) {
                    // Update the title in the cache because it is used all over the place and we want it to be reflected around the app.
                    if (node.type === "main-heading") {
                        cache.modify({
                            id: `Tablet:${tabletId}`,
                            fields: {
                                latest(latest) {
                                    return { ...latest, title: node.children?.[0]?.text };
                                },
                            },
                        });
                    }
                },
            });
        });

        // const movesInProgress = moved.map((node) => {
        //     return upsertBlock({
        //         variables: {
        //             input: {
        //                 uuid: node.id,
        //                 content: JSON.stringify(node),
        //                 order: getBlockPlacement(currentEditorValue, node.id),
        //                 tabletId,
        //             },
        //         },
        //     });
        // });

        const removalsInProgress = removed.map(({ id }) => {
            return deleteBlock({ variables: { input: { blockId: id, tabletId } } });
        });

        const response = await Promise.all([
            ...additionsInProgress,
            ...modificationsInProgress,
            ...removalsInProgress,
        ]);

        /* I should do something smart here like check over all the responses (data.upsertBlock.id) and
        remove the ones that succeeded from the changes to send next round. For the ones that didn't succeed
        either keep them around or if they have been changed again in the meantime merge them in. This would
        make this autosave really tolerant to failure. If I then implemented a way to store pending edits in
        localstorage I could make it so that failures were very very unlikely. For now though this is too much
        work so I'll double back to this later. I'll need to handle cases like the removal failed because it had
        already been removed as well (either on the frontend or backend) */

        const saveSuccessful = response.every(({ error }) => !error);

        setFlushing(false);

        const orderMap = createOrderMap(currentEditorValue);
        await orderAllBlocks({ variables: { input: { blocks: JSON.stringify(orderMap), tabletId } } });

        if (saveSuccessful) {
            if (!hasChangesToSync(autoSaveStateChanges.current)) setHasPendingChanges(false);
        } else {
            window.alert(
                "There were some issues with saving the content you created in the last 10 seconds. Please refresh the page and continue editing. If this happens again please email us directly at support@scribe.wiki"
            );
        }
    }

    function hasChangesToSync({ added, removed, modified, moved }) {
        return added.length + removed.length + modified.length + moved.length !== 0;
    }

    function resetStateChanges() {
        autoSaveStateChanges.current = { added: [], removed: [], modified: [], moved: [] };
        //editorValueRef.current = null;
    }

    function createOrderMap(state) {
        return state.reduce(
            (orderMap, node, i) => ({
                ...orderMap,
                [node.id]: i,
            }),
            {}
        );
    }

    function getBlockPlacement(state, id) {
        return state.findIndex((node) => node.id === id) + 1;
    }

    // Treat this like a transaction, no persisting until this has been finished
    function queueStateChanges(entireNewState, entireCurrentState) {
        const newState = removeUnpersistedNodes(entireNewState);
        const currentState = removeUnpersistedNodes(entireCurrentState);
        if (newState === currentState || !currentState) return;
        transactionInProgressRef.current.value = true;

        const removedNodes = currentState.filter(({ id }) => !newState.some((node) => node.id === id));
        const addedNodes = newState.filter(({ id }) => !currentState.some((node) => node.id === id));
        const unmodifiedNodes = newState.filter((node) => currentState.includes(node));
        const modifiedNodes = newState.filter(
            (node) => !unmodifiedNodes.includes(node) && !addedNodes.includes(node)
        );
        const movedNodes = newState.filter(({ id }, i) => currentState[i]?.id !== id);

        const recentlyAddedNodes = autoSaveStateChanges.current.added;
        const recentlyModifiedNodes = autoSaveStateChanges.current.modified;
        const recentlyRemovedNodes = autoSaveStateChanges.current.removed;
        const recentlyMovedNodes = autoSaveStateChanges.current.moved;

        const removedNodesToCommit = removedNodes.reduce((acc, removedNode) => {
            const matchingAddedNode = recentlyAddedNodes.find(({ id }) => id === removedNode.id);
            const matchingModifiedNode = recentlyModifiedNodes.find(({ id }) => id === removedNode.id);

            // First check if it was created and deleted in this 10 second interval. If it was we don't need
            // to do anything further
            if (matchingAddedNode) {
                autoSaveStateChanges.current.added = recentlyAddedNodes.filter(
                    (node) => node !== matchingAddedNode
                );
                return acc;
            }
            // Then check if there were any unsaved modifications to this node. Removal trumps modification.
            if (matchingModifiedNode) {
                autoSaveStateChanges.current.modified = recentlyModifiedNodes.filter(
                    (node) => node !== matchingModifiedNode
                );
                return [...acc, removedNode];
            }

            return [...acc, removedNode];
        }, []);

        const modifiedNodesToCommit = modifiedNodes.reduce((acc, modifiedNode) => {
            const matchingAddedNode = recentlyAddedNodes.find(({ id }) => id === modifiedNode.id);
            const matchingModifiedNode = recentlyModifiedNodes.find(({ id }) => id === modifiedNode.id);

            // First check if the node was added in this round. We can combine adding and modifying a node
            // since we just need to change the content being added. We could have moved the added node to
            // the modified pile but then we can't tell if it was added this round. We need this information
            // if it's also removed in this round.
            if (matchingAddedNode) {
                const addedNodeIndex = recentlyAddedNodes.findIndex((node) => node === matchingAddedNode);
                autoSaveStateChanges.current.added.splice(addedNodeIndex, 1, modifiedNode);

                return acc;
            }

            // No point modifying the same thing twice, especially when API requests aren't garunteed to
            // arrive in order
            if (matchingModifiedNode) {
                autoSaveStateChanges.current.modified = recentlyModifiedNodes.filter(
                    (node) => node !== matchingModifiedNode
                );
            }

            return [...acc, modifiedNode];
        }, []);

        const addedNodesToCommit = addedNodes.reduce((acc, addedNode) => {
            const matchingRemovedNode = recentlyRemovedNodes.find(({ id }) => id === addedNode.id);

            // This exists because of undo/redo. If we undo a node it is removed, but we can feasibly
            // redo that and add it again. If this happens we can just stop the removal from going ahead.
            if (matchingRemovedNode) {
                autoSaveStateChanges.current.removed = recentlyRemovedNodes.filter(
                    (node) => node !== recentlyRemovedNodes
                );
            }

            return [...acc, addedNode];
        }, []);

        const movedNodesToCommit =
            unmodifiedNodes.length === newState.length
                ? movedNodes.reduce((acc, movedNode) => {
                      const matchingMovedNodes = recentlyMovedNodes.find(({ id }) => id === movedNode.id);

                      if (!matchingMovedNodes) return [...acc, movedNode];
                      else return [...acc];
                  }, recentlyMovedNodes)
                : [];

        // console.log("REMOVED TO COMMIT: ", removedNodesToCommit);
        // console.log("ADDED TO COMMIT: ", addedNodesToCommit);
        // console.log("MODIFIED TO COMMIT: ", modifiedNodesToCommit);

        autoSaveStateChanges.current = {
            added: [...autoSaveStateChanges.current.added, ...addedNodesToCommit],
            modified: [...autoSaveStateChanges.current.modified, ...modifiedNodesToCommit],
            removed: [...autoSaveStateChanges.current.removed, ...removedNodesToCommit],
            moved: movedNodesToCommit,
        };

        editorValueRef.current = newState;

        setHasPendingChanges(true);
        transactionInProgressRef.current.value = false;
    }

    useEffect(() => {
        //syncToApi(autoSaveStateChanges.current);
        resetStateChanges();
        editorValueRef.current = null;
    }, [tabletId]);

    // useEffect(() => {
    //     if (!tabletId || !editorState) return;
    //     queueStateChanges(editorState, prevEditorState);
    // }, [tabletId, editorState]);

    useEffect(() => {
        const autoSave = setInterval(() => {
            if (transactionInProgressRef.current.value) {
                transactionInProgressRef.current.registerFinishListener(() => {
                    syncToApi(autoSaveStateChanges.current);
                });
            } else {
                syncToApi(autoSaveStateChanges.current);
            }
        }, 10000);

        return () => clearInterval(autoSave);
    }, [tabletId]);

    // If the browser is closed or refreshed let's send off pending changes
    useEffect(() => {
        function handleBeforeUnload(event) {
            if (!tabletId) return;

            // Until we have a better system its best to stop them from leaving until we send the final updates
            if (hasChangesToSync(autoSaveStateChanges.current)) {
                event.preventDefault();
                event.returnValue = "";
            }

            syncToApi(autoSaveStateChanges.current);
        }

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [tabletId]);

    async function manualSave() {
        if (!tabletId) return;
        await syncToApi(autoSaveStateChanges.current);
    }

    useEffect(() => {
        function handleRouteChange() {
            manualSave();
        }

        Router.events.on("routeChangeStart", handleRouteChange);

        return () => Router.events.off("routeChangeStart", handleRouteChange);
    }, [tabletId]);

    return { queueStateChanges, manualSave, hasPendingChanges, flushing };
}

/**
 * Records a page view whenever it detects that the user has come to the current page from a different
 * location. It does not count refreshes.
 * @param {string} pageId The ID of the page we wish to record
 */
export function useRecordPageView(pageId, instanceId, workspaceId) {
    const [recordPageView] = useMutation(RECORD_PAGE_VIEW);
    const [hasChangedRoutes] = useHasChangedRoutes();

    useEffect(() => {
        if (hasChangedRoutes) {
            const options = {
                variables: { input: { tablet: pageId } },
                update(cache, { data: { recordTabletView: recordedView } }) {
                    const pageViewData = cache.readQuery({ query: PAGE_VIEWS, variables: { workspaceId } });

                    cache.writeQuery({
                        query: PAGE_VIEWS,
                        variables: { workspaceId },
                        data: {
                            tabletViews: [recordedView, ...pageViewData.tabletViews],
                        },
                    });
                },
            };

            // if (instanceId) {
            //     options.optimisticResponse = {
            //         __typename: "Mutation",
            //         recordTabletView: {
            //             __typename: "TabletView",
            //             viewedAt: new Date().toISOString(),
            //             tablet: {
            //                 __typename: "Tablet",
            //                 id: pageId,
            //                 latest: {
            //                     __typename: "TabletInstance",
            //                     id: instanceId,
            //                 },
            //             },
            //         },
            //     };
            // }

            recordPageView(options);
        }
    }, [pageId, hasChangedRoutes]);
}

export function useCreateNewPage() {
    const [upsertTodaysDateCollection] = useMutation(UPSERT_TODAYS_DATE_COLLECTION);
    const workspaceId = useWorkspace();
    const [createPage] = useMutation(CREATE_PAGE);
    // We can't use apollo client for the loading here because we make two seperate calls
    const [loading, setLoading] = useState(false);
    const generateWorkspaceUrl = useLazyWorkspaceUrl();

    async function createNewPage(
        args = {},
        navigateToPage = true,
        { workspaceId: workspaceIdOverride } = {}
    ) {
        setLoading(true);
        const pageUUID = generateEntityId();
        const todayIsoString = formatISO(new Date(), { representation: "date" });
        let parentCollectionRes = await upsertTodaysDateCollection({
            variables: { input: { date: todayIsoString, workspace: workspaceIdOverride || workspaceId } },
        });

        const parentCollection = parentCollectionRes.data.upsertDateCollection;

        const res = await createPage({
            variables: {
                input: { uuid: pageUUID, collection: parentCollection.id, ...args },
            },
            update(cache, { data: { createTablet: newPage } }) {
                // TODO: This relies on the tablet ID never changing which is bad because it will. Come up with a more robust way to
                // get the cache object and update it - e.g. pass around cached objects instead of IDs or come up with a better architecture
                if (args.connectedTablets) {
                    for (let connectedPage of args.connectedTablets) {
                        cache.modify({
                            id: `Tablet:${connectedPage}`,
                            fields: {
                                connectedTablets(connectedTablets) {
                                    return [...connectedTablets, newPage];
                                },
                            },
                        });
                    }
                }

                const workspaceData = cache.readQuery({
                    query: GET_WORKSPACE,
                    variables: { id: workspaceId },
                });

                // This can be called outside the context of a workspace (e.g. the login page) so we
                // can't assume the workspace is cached.
                if (workspaceData?.workspace) {
                    cache.modify({
                        id: cache.identify(workspaceData.workspace),
                        fields: {
                            tablets(tablets) {
                                return [...tablets, newPage];
                            },
                        },
                    });
                }
            },
        });

        if (!res.errors && navigateToPage) {
            const newPageUrl = generateWorkspaceUrl("page/[page]/edit", `page/${pageUUID}/edit`, {
                workspaceId: workspaceIdOverride || workspaceId,
            });

            Router.push(newPageUrl.href, newPageUrl.as, { shallow: true });
        }

        setLoading(false);

        return res;
    }

    return [createNewPage, { loading }];
}

function normaliseCollectionNavItem(collection) {
    if (!collection) return {};

    const { connectedCollections = [], tablets = [] } = collection;
    return {
        id: collection.id,
        name: collection.name,
        type: "collection",
        expanded: false,
        children: [
            ...[...connectedCollections]
                .filter(({ name }) => name)
                .map((collection) => normaliseCollectionNavItem(collection)),
            ...[...tablets]
                .filter(({ latest }) => latest?.title)
                .map((tablet) => normaliseTabletNavItem(tablet)),
        ],
    };
}

function normaliseTabletNavItem(tablet) {
    if (!tablet) return {};
    const { collections = [], connectedTablets = [] } = tablet;

    return {
        id: tablet.id,
        name: tablet.latest?.title || "Untitled",
        type: "page",
        expanded: false,
        children: [
            ...[...collections]
                .filter(({ name }) => name)
                .map((collection) => normaliseCollectionNavItem(collection)),
            ...[...connectedTablets]
                .filter(({ latest }) => latest?.title)
                .map((tablet) => normaliseTabletNavItem(tablet)),
        ],
    };
}

const handleToggleExpand = (allCollections, allTablets, folderMap) => (id, isExpanded, path) => {
    function getNode(folderMapSlice, path) {
        if (path.length === 1) return folderMapSlice[path[0]];
        else return getNode(folderMapSlice[path[0]].children, path.slice(1));
    }

    function getAncestorNodes(folderMap, path) {
        const ancestorNodes = [];
        let currentLayer = folderMap;

        for (let level = 0; level < path.length; level++) {
            const currentNode = currentLayer[path[level]];
            ancestorNodes.push(currentNode);
            currentLayer = currentNode.children;
        }

        return ancestorNodes.reverse();
    }

    if (!isExpanded) {
        const newFolderState = produce(folderMap, (draftState) => {
            // This is the node you clicked on
            const node = getNode(draftState, path);

            // We are now going through all it's children which are always loaded ahead of time
            node.children.forEach((child, i) => {
                const childPath = [...path, i];

                // We want to remove any children that are also ancestors higher in the tree, otherwise we get
                // duplicate items appearing because entities are doubly linked in Scribe.
                const ancestorNodes = getAncestorNodes(folderMap, childPath);

                if (child.type === "collection") {
                    const collection = allCollections.find(({ id }) => id === child.id);
                    const childrenNodes = normaliseCollectionNavItem(collection).children || [];
                    child.children = childrenNodes.filter(({ id }) => {
                        return ancestorNodes.every((ancestorNode) => ancestorNode.id !== id);
                    });
                } else if (child.type === "page") {
                    const tablet = allTablets.find(({ id }) => id === child.id);
                    const childrenNodes = normaliseTabletNavItem(tablet).children || [];

                    child.children = childrenNodes.filter(({ id }) => {
                        return ancestorNodes.every((ancestorNode) => ancestorNode.id !== id);
                    });
                }
            });

            node.expanded = true;
        });

        return newFolderState;
    } else {
        const newFolderState = produce(folderMap, (draftState) => {
            const node = getNode(draftState, path);
            node.expanded = false;
        });

        return newFolderState;
    }
};

export function useDynamicBookmarkFolderSystem(workspaceId) {
    const { visibleBookmarks, onBookmarksExpand } = useContext(navigationContext);

    const { data: workspaceQueryRes } = useQuery(GET_WORKSPACE, { variables: { id: workspaceId } });
    const { data: pageBookmarksRes } = useQuery(PAGE_BOOKMARKS, { variables: { workspaceId } });
    const { data: collectionBookmarksRes } = useQuery(COLLECTION_BOOKMARKS, { variables: { workspaceId } });

    const workspace = workspaceQueryRes?.workspace || {};
    const pageBookmarks = pageBookmarksRes?.tabletBookmarks;
    const collectionBookmarks = collectionBookmarksRes?.collectionBookmarks;

    useEffect(() => {
        if (!pageBookmarks || !collectionBookmarks) return;

        //const tabletBookmarks = [...user.tabletBookmarks].filter(({ tablet }) => !!tablet.deletedAt);

        const bookmarks = [...collectionBookmarks, ...pageBookmarks];
        const sortedBookmarks = bookmarks.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
        );

        const folderState = sortedBookmarks.map((bookmark) => {
            switch (bookmark.__typename) {
                case "TabletBookmark": {
                    const existingNormalisedTabletNavItem = visibleBookmarks.find(
                        ({ id }) => id === bookmark.tablet.id
                    );
                    if (existingNormalisedTabletNavItem) return existingNormalisedTabletNavItem;
                    else return normaliseTabletNavItem(bookmark.tablet);
                }
                case "CollectionBookmark": {
                    const existingNormalisedTabletNavItem = visibleBookmarks.find(
                        ({ id }) => id === bookmark.collection.id
                    );
                    if (existingNormalisedTabletNavItem) return existingNormalisedTabletNavItem;
                    else return normaliseCollectionNavItem(bookmark.collection);
                }
            }
        });

        onBookmarksExpand(folderState);
    }, [pageBookmarks, collectionBookmarks]);

    const handleToggleBookmarkFolder = useCallback(
        (id, isExpanded, path) => {
            const newFolderState = handleToggleExpand(
                workspace.collections,
                workspace.tablets,
                visibleBookmarks
            )(id, isExpanded, path);

            onBookmarksExpand(newFolderState);
        },
        [workspace, visibleBookmarks]
    );

    return [visibleBookmarks, handleToggleBookmarkFolder];
}

export function useDynamicRecentFolderSystem(workspaceId) {
    const { visibleHistory, onHistoryExpand } = useContext(navigationContext);

    const { data: workspaceQueryRes } = useQuery(GET_WORKSPACE, { variables: { id: workspaceId } });
    const { data: pageViewsRes } = useQuery(PAGE_VIEWS, { variables: { workspaceId } });
    //const page = usePage();

    const workspace = workspaceQueryRes?.workspace || {};
    const pageViews = pageViewsRes?.tabletViews;

    //const folderStateWithoutCurrentPage = visibleHistory.filter(({ id }) => id !== page?.id);

    useEffect(() => {
        if (!pageViews) return;

        // I need to sort the tabletViews because the cache can be updated on the client with unsorted results
        const folderState = [...pageViews]
            .sort((a, b) => (a.viewedAt < b.viewedAt ? 1 : a.viewedAt > b.viewedAt ? -1 : 0))
            .map(({ tablet }) => tablet)
            .filter(
                (tablet, index, self) => tablet && self.findIndex((t2) => t2?.id === tablet?.id) === index
            )
            .slice(0, 8)
            .map((tablet) => {
                const existingNormalisedTabletNavItem = visibleHistory.find(({ id }) => id === tablet.id);
                if (existingNormalisedTabletNavItem)
                    return { ...existingNormalisedTabletNavItem, name: tablet?.latest?.title || "Untitled" };
                else return normaliseTabletNavItem(tablet);
            });

        onHistoryExpand(folderState);
    }, [pageViews]);

    function handleToggleRecentFolder(id, isExpanded, path) {
        const newFolderState = handleToggleExpand(workspace.collections, workspace.tablets, visibleHistory)(
            id,
            isExpanded,
            path
        );

        onHistoryExpand(newFolderState);
    }

    return [visibleHistory, handleToggleRecentFolder];
}
