import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, Text, Range, Path, Node, Element } from "slate";
import { isUrl, isEmail } from "@modules/string-filters";
import escapeHtml from "escape-html";

const LIST_TYPES = ["numbered-list", "bulleted-list", "check-list"];

/**
 * Toggle formatting that is applied to the mark. The marks property stores formatting that is
 * attached to the cursor, and that will be applied to the text that is inserted next.
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The prop passed to the Leaf component whose boolean state you wish to toggle
 */
export function toggleMark(editor, format) {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
}

/**
 * Toggle the type of block our selection currently belongs to to the specified format.
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The type prop passed to the Element component whose boolean state you wish to toggle
 */
export function toggleBlock(editor, format, extraAttrs = {}) {
    const isActive = isBlockActive(editor, format);
    const isList = isBlockFormatOneOf(editor, LIST_TYPES);
    const hasSelectedListType = LIST_TYPES.includes(format);
    const isCheckList = format === "check-list";

    let type = format;
    if (isActive) type = "paragraph";
    else if (isCheckList) type = "check-list-item";
    else if (hasSelectedListType) type = "list-item";

    if (isList && hasSelectedListType && !isActive) {
        const [parentListElement, parentListPath] = getParentListElement(
            editor,
            editor.selection.anchor.path
        );

        Transforms.setNodes(editor, { type: format, ...extraAttrs }, { at: parentListPath });

        let i = 0;

        // We can't just give setNodes a range that encompasses the children because ranges include
        // text nodes. If this list has nested lists inside it then we will end up setting those list
        // items as well because we can't differentiate, and Slate doesn't seem to have the concept of
        // an element level range. So we do the next best thing and set the items individually.
        for (i = 0; i < parentListElement.children.length; i++) {
            const listItemPath = [...parentListPath, i];
            const listItemElement = Node.get(editor, listItemPath);
            if (isListItemElement(listItemElement)) {
                Transforms.setNodes(
                    editor,
                    { type },
                    { at: listItemPath, match: (n) => isListItemElement(n) }
                );
            }
        }
    } else if (hasSelectedListType && !isActive) {
        Transforms.setNodes(editor, { type });
        Transforms.wrapNodes(editor, { type: format, children: [], ...extraAttrs });
    } else if (isList) {
        // TODO: Unwrap nested lists properly - we only do one level at the moment which causes bugs
        Transforms.unwrapNodes(editor, {
            match: (n) => LIST_TYPES.includes(n.type),
            split: true,
            at: editor.selection,
        });

        Transforms.setNodes(editor, { type });
    } else {
        // Our main rule here for lists is that the node is lifted to the root and then transformed
        // The transform will have to run the deletion algorithm as many times as levels needed to get
        // to root. This is because the position of items below it (both depth and breadth) change at
        // each level and each change depends on the last

        // We remove whatever the parent of our current node
        Transforms.unwrapNodes(editor, {
            match: (n) => LIST_TYPES.includes(n.type),
            //split: true,
            at: editor.selection,
        });

        Transforms.setNodes(editor, { type, ...extraAttrs });
    }
}

export function unwrapLink(editor, selection) {
    Transforms.unwrapNodes(editor, { match: (n) => n.type === "link", at: editor.selection || selection });
}

export function toggleLink(editor, text, selection) {
    if (isBlockActive(editor, "link", selection)) {
        unwrapLink(editor, selection);
    } else {
        const currentSelection = editor.selection || selection;
        const isCollapsed = currentSelection && Range.isCollapsed(currentSelection);

        let link = { type: "link", children: isCollapsed ? [{ text }] : [] };
        if (isUrl(text)) {
            link = { ...link, url: text };
        } else if (isEmail(text)) {
            link = { ...link, email: text };
        }

        if (isCollapsed) {
            Transforms.insertNodes(editor, link, { at: currentSelection, select: true });
        } else {
            Transforms.wrapNodes(editor, link, { split: true, at: currentSelection });
            Transforms.collapse(editor, { edge: "end" });
        }
    }
}

export function toggleColor(editor, color) {
    const isActive = isColorActive(editor, color);

    Transforms.setNodes(
        editor,
        { color: isActive || color === "default" ? null : color },
        { match: (n) => Text.isText(n), split: true, at: editor.selection }
    );
}

export function toggleBackgroundColor(editor, color) {
    const isActive = isBackgroundColorActive(editor, color);

    Transforms.setNodes(
        editor,
        { backgroundColor: isActive || color === "default" ? null : color },
        { match: (n) => Text.isText(n), split: true, at: editor.selection }
    );
}

/**
 * Check if a particular format is applied to the block that contains the currently selected text.
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The type you wish to check for the existence for on the block
 */
export function isBlockActive(editor, format, selection) {
    if (!shouldEvaluate(editor, selection)) return;

    return getSelectedBlockFormat(editor, selection) === format;
}

export function isBlockFormatOneOf(editor, formats) {
    if (!shouldEvaluate(editor)) return;

    return formats.includes(getSelectedBlockFormat(editor));
}

export function getSelectedBlockFormat(editor, selection) {
    if (!shouldEvaluate(editor, selection)) return;

    // We don't toggle list items, we toggle the list itself, so we avoid item elements in the search

    let type;

    for (let [node] of Editor.nodes(editor, {
        at: selection || editor.selection,
        match: (n) => Element.isElement(n) && !isListItemElement(n),
        mode: "lowest",
    })) {
        if (!type) type = node?.type;
        else if (type !== node?.type) {
            return "multiple";
        }
    }

    return type;
}

export function isColorActive(editor, color, selection) {
    const selectedTextColor = getSelectedTextColor(editor, selection);

    if (!selectedTextColor && color === "default") return true;
    else return selectedTextColor === color;
}

export function isBackgroundColorActive(editor, color, selection) {
    const selectedBackgroundTextColor = getSelectedTextBackgroundColor(editor, selection);

    if (!selectedBackgroundTextColor && color === "default") return true;
    else return selectedBackgroundTextColor === color;
}

export function getSelectedTextColor(editor, selection) {
    if (!shouldEvaluate(editor, selection)) return;

    const textNodes = getAllTextNodesInSelection(editor, selection);
    const selectedTextColors = textNodes.map((node) => node.color);
    const areAllEqual = selectedTextColors.every((val, i, arr) => val === arr[0]);

    // Multi isn't actually used anywhere - it's meant to make it so no other options match (because there value isn't multi)
    return areAllEqual ? selectedTextColors[0] : "multi";
}

export function getSelectedTextBackgroundColor(editor, selection) {
    if (!shouldEvaluate(editor, selection)) return;

    const textNodes = getAllTextNodesInSelection(editor, selection);
    const selectedTextColors = textNodes.map((node) => node.backgroundColor);
    const areAllEqual = selectedTextColors.every((val, i, arr) => val === arr[0]);

    // Multi isn't actually used anywhere - it's meant to make it so no other options match (because there value isn't multi)
    return areAllEqual ? selectedTextColors[0] : "multi";
}

/**
 * Checks if a particular format is attached to the mark. The marks property stores formatting that is
 * attached to the cursor, and that will be applied to the text that is inserted next.
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The property you wish to check for the existence for on the mark
 */
export function isMarkActive(editor, format) {
    if (!shouldEvaluate(editor)) return;

    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
}

/**
 * Toggle the format applied to selected leaf nodes. We only consider formats that have been
 * applied to the entire selection already to be active. If only some of the nodes have this
 * format applied already then this will simply apply that format to the rest of the nodes.
 *
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The property you wish to toggle
 */
export function toggleFormat(editor, format, extraAttrs = {}) {
    const isActive = isFormatCompletelyActive(editor, format);

    Transforms.setNodes(
        editor,
        { [format]: isActive ? null : true, ...extraAttrs },
        { match: (n) => Text.isText(n), split: true, at: editor.selection }
    );
}

/**
 * Checks if at least one of the nodes currently selected has the format in question applied to it
 *
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The format you want to check is applied (e.g. "bold", "italic", ect)
 */
export function isFormatPartiallyActive(editor, format) {
    if (!shouldEvaluate(editor)) return;

    const [match] = Editor.nodes(editor, {
        match: (n) => n[format] === true,
        mode: "all",
        at: editor.selection,
    });

    return !!match;
}

/**
 * Checks if all of the nodes currently selected have the format in question applied to it
 *
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @param {string} format The format you want to check is applied (e.g. "bold", "italic", ect)
 */
export function isFormatCompletelyActive(editor, format, selection) {
    if (!shouldEvaluate(editor, selection)) return;

    const textNodes = getAllTextNodesInSelection(editor, selection);
    const allTextNodesFormatted = textNodes.every((node) => node[format]);

    return allTextNodesFormatted;
}

/**
 * Return an iterable of all the node entries of a root node. Each entry is
 * returned as a `[Node, Path]` tuple.
 *
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @returns {[[Node, Path]]} A array of tuples where the path refers to node's
 * position inside the root node.
 */
function getAllTextEntriesInSelection(editor, selection) {
    const [...matches] = Editor.nodes(editor, {
        match: (n) => Text.isText(n),
        at: editor.selection || selection,
    });

    /* Editor.nodes will include any node that happens to start where the selection ends. This is not
    ideal in this case as we only want nodes that have at least one letter within our selection. To
    work around this we detect if our selections anchor and focus aren't offset. This means that the
    anchor starts at the beginning of one element and the focus finishes at the beginning of the next.
    In this case we know Editor.nodes will include the next element so we manually ignore it.
    */
    const oneElementCompletelySelected =
        editor.selection?.anchor.offset === 0 && editor.selection?.focus.offset === 0;

    if (oneElementCompletelySelected) return matches.slice(0, -1);
    else return matches;
}

/**
 * Return an iterable of all the nodes of a root node.
 *
 * @param {Editor} editor The Editor whose state you wish to manipulate
 * @returns {Node[]} A array of tuples where the path refers to node's
 * position inside the root node.
 */
function getAllTextNodesInSelection(editor, selection) {
    const textNodeEntries = getAllTextEntriesInSelection(editor, selection);

    return textNodeEntries.map(([node]) => node);
}

/**
 * This function exists because the editor is controlled by other components. This means that the state
 * of the editor can change at any time, even being reset (as is the case on the collection page). Unfortunately
 * there is a mismatch when this happens where the selection is set to a node that doesn't exist. When
 * you then try to query the editor at a non-existent node it will throw an error causing everything to
 * crash and burn. Eventually I would like to commit a change that adjusts the selection based of editor
 * value changes but for now this shall do.
 * @param {Editor} editor The Editor whose state you wish to check
 */
function shouldEvaluate(editor, selection) {
    const selectionToEvaluate = selection || editor.selection;
    if (!selectionToEvaluate) return;

    const nodeExists = Node.has(editor, selectionToEvaluate.anchor.path);

    return nodeExists;
}

export function isListElement(element) {
    const isListType =
        element?.type === "bulleted-list" ||
        element?.type === "numbered-list" ||
        element?.type === "check-list";

    const hasChildren = Array.isArray(element?.children);

    return isListType && hasChildren;
}

export function isLinkElement(element) {
    const isListType = element?.type === "link";
    const hasChildren = Array.isArray(element?.children);

    return isListType && hasChildren;
}

export function isListItemElement(element) {
    const isListItemType = element?.type === "list-item" || element?.type === "check-list-item";
    const hasAtLeastOneChild = Array.isArray(element?.children);
    const childIsTextNode = hasAtLeastOneChild ? Text.isText(element?.children[0]) : false;

    return isListItemType && childIsTextNode;
}

export function isPlaceholderElement(element) {
    return element?.type === "placeholder";
}

/**
 * To ensure that nested lists can't skip a level, and to make sure a
 * nodes sub-list doesn't become detached we need to lift the sub-list
 * up (if it exists) as well. Doing this before the node in question
 * makes life easier because it ensures we never get in strange situations
 * we have to fix like having a list as the only child of another list.
 *
 * In this first step if we didn't get the parent first we would be referring
 * to the text node when getting the next path, not the list item.
 */

/**
 * This is a trick one. There are five comandments you need to apply in order to get lists to play nicely
 * when a list item is deleted.
 *
 * Rising Up
 * Deleting a node will lift it up by one level if it is not at the root level
 *
 * Node Stealing
 * If the node being deleted if not at the root level has one or more sibling node after it then when it is lifted up it's sibling
 * nodes become its children, they are stolen from the node they lived in.
 *
 * Rising Tide
 * Lift up all descendants of the node being deleted by an equal amount
 *
 * Fixed descendants
 * When deleting a node (lifting or turning into a paragraph) you must never change the position of its
 * descendants relative to one another
 *
 * Transformation
 * All nodes at the root level turn into paragraphs when deleted
 * @param {*} editor
 */
export function liftListItem(editor, listItemPath) {
    const listItem = Node.get(editor, listItemPath);
    if (!isListItemElement(listItem)) throw new Error(`Element at ${listItemPath} is not a list item`);

    const nextSiblingPath = Path.next(listItemPath);
    if (Node.has(editor, nextSiblingPath)) {
        const nextSibling = Node.get(editor, nextSiblingPath);

        if (isListElement(nextSibling)) {
            Transforms.unwrapNodes(editor, {
                at: nextSiblingPath,
                split: true,
            });
        }
    }

    Transforms.unwrapNodes(editor, {
        match: (n) => Editor.isBlock(editor, n) && isListElement(n),
        mode: "lowest",
        split: true,
        at: listItemPath,
    });

    // If the list item was in a top level list then it should be turned into a paragraph
    if (listItemPath.length === 2) Transforms.setNodes(editor, { type: "paragraph" });
}

const MAX_DEPTH = 2;

export function getParentListElement(editor, listItemPath) {
    const [parentListElement, parentListPath] = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n) && isListElement(n),
        mode: "lowest",
        at: listItemPath,
    });

    return [parentListElement, parentListPath];
}

export function getListDepth(editor, listItemPath) {
    const [parentListElement] = getParentListElement(editor, listItemPath);

    const { depth } = parentListElement;
    const parsedDepth = depth ? parseInt(depth, 10) : 0;

    return parsedDepth;
}

export function canLiftListItem(editor, listItemPath) {
    const parsedDepth = getListDepth(editor, listItemPath);

    return parsedDepth - 1 >= 0;
}

export function canNestListItem(editor, listItemPath) {
    const [parentListElement] = getParentListElement(editor, listItemPath);

    const { depth } = parentListElement;
    const parsedDepth = depth ? parseInt(depth, 10) : 0;

    const maxDepthReached = parsedDepth + 1 > MAX_DEPTH;
    const isOnlyListItem = parentListElement.children.length < 2;
    const isFirstItemInList = listItemPath[listItemPath.length - 1] === 0;

    // We don't allow users to nest a list item if it is the only one
    // inside the list since there is no reason to do so.
    return !maxDepthReached && !isOnlyListItem && !isFirstItemInList;
}

export function nestListItem(editor, listItemPath, listType) {
    const parsedDepth = getListDepth(editor, listItemPath);
    const newBoundedDepth = Math.min(parsedDepth + 1, MAX_DEPTH);

    Transforms.wrapNodes(editor, {
        type: listType,
        depth: newBoundedDepth,
    });

    // We can't nest this list items children further so there is no point continuing
    if (newBoundedDepth === MAX_DEPTH) return;

    // If the nested item now has siblings with a higher path than we should wrap those
    // in a list as well
    const newlyNestedListItemPath = Path.parent(editor.selection.anchor.path);
    const siblingPlacement = newlyNestedListItemPath[newlyNestedListItemPath.length - 1];
    const [parentListElement, parentListPath] = getParentListElement(editor, newlyNestedListItemPath);
    const numChildren = parentListElement.children.length;

    // If there are siblings directly after the newly wrapped list item
    if (siblingPlacement < numChildren - 1) {
        const nextSiblingPath = Path.next(newlyNestedListItemPath);
        const lastSiblingPath = parentListPath.concat(numChildren - 1);

        const rangeToWrap = {
            anchor: { offset: 0, path: nextSiblingPath },
            focus: { offset: 0, path: lastSiblingPath },
        };

        Transforms.wrapNodes(
            editor,
            {
                type: listType,
                depth: newBoundedDepth + 1,
            },
            { at: rangeToWrap }
        );

        // TODO: Update depth of item
        // for (const [node, path] of Node.elements(editor, {
        //     from: newlyNestedListItemPath,
        // })) {
        //     console.log(node);
        // }
    }
}

export function getBoundedNextPath(editor, path) {
    const [parentElement, parentPath] = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n) || Editor.isEditor(editor, n),
        mode: "lowest",
        at: path,
    });
    const siblingPlacement = path[path.length - 1];
    const numChildren = parentElement.children.length;

    if (siblingPlacement < numChildren - 1) {
        return Path.next(path);
    } else {
        return path;
    }
}

export function getBoundedPreviousPath(editor, path) {
    const siblingPlacement = path[path.length - 1];

    if (siblingPlacement > 0) {
        return Path.previous(path);
    } else {
        return path;
    }
}

export function isValidListItemForParent(listItemElement, listElement) {
    return (
        (listItemElement?.type === "list-item" &&
            (listElement?.type === "bulleted-list" || listElement?.type === "numbered-list")) ||
        (listItemElement?.type === "check-list-item" && listElement?.type === "check-list")
    );
}

export function isMainHeadingSelected(editor) {
    const [topLevelElement] = Editor.nodes(editor, {
        at: editor.selection,
        match: (n) => Element.isElement(n),
        mode: "highest",
    });

    const [topLevelNode, topLevelPath] = topLevelElement || [];

    return topLevelNode?.type === "main-heading";
}

export function getNodeText(node) {
    if (isPlaceholderElement(node)) {
        return "";
    } else if (Text.isText(node)) {
        return node.text;
    } else {
        return node.children.map(getNodeText).join("");
    }
}

export function getDefaultPlaceholderText(elementType) {
    switch (elementType) {
        case "main-heading":
            return "Untitled";
        case "paragraph":
            return "Type  /  for commands. Highlight text for more options.";
    }
}

export function serializeHTML(node) {
    if (!node) return "";
    if (Text.isText(node)) {
        return escapeHtml(node.text);
    }

    const children = node.children.map((n) => serializeHTML(n)).join("");

    switch (node.type) {
        case "main-heading":
            return `<h1>${children}</h1>`;
        case "minor-heading":
            return `<h2>${children}</h2>`;
        case "sub-heading":
            return `<h3>${children}</h3>`;
        case "bulleted-list":
            return `<ul>${children}</ul>`;
        case "numbered-list":
            return `<ol>${children}</ol>`;
        case "list-item":
            return `<li>${children}</li>`;
        case "quote":
            return `<blockquote><p>${children}</p></blockquote>`;
        case "paragraph":
            return `<p>${children}</p>`;
        case "link":
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        default:
            return children;
    }
}

/**
 * Check if a descendant node exists at a specific path.
 */

export function exists(root, path) {
    if (!path) return false;
    let node = root;

    for (let i = 0; i < path.length; i++) {
        const p = path[i];

        if (!node.children[p]) {
            return false;
        }

        node = node.children[p];
    }

    return true;
}
