import { useContext } from "react";
import { pageContext } from "@context/page";

/**
 * Get access to the page details if you are a component in a page
 */
export function usePage() {
    const page = useContext(pageContext);

    return page;
}
